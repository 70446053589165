<template>
  <div>
    <c-search-box @enter="getEquipClassList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="hazardMachineItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hazardMachineFlag"
            label="유해위험기계기구 여부"
            v-model="searchParam.hazardMachineFlag"
          ></c-select>
        </div>
          <!-- 사용여부 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-tree-table
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="gridClass.columns"
          :data="gridClass.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getEquipClassList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="classInsTable"
          title="설비유형별 세부항목"
          tableId="classInsTable"
          :columns="insItemgrid.columns"
          :data="insItemgrid.data"
          :columnSetting="false"
          selection="multiple"
          :usePaging="false"
          rowKey="sortOrder"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                  <q-avatar icon="push_pin" color="green" text-color="white" />
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && insItemgrid.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn
                  v-if="editable && insItemgrid.data.length > 0"
                  :url="insertUrl"
                  :isSubmit="isSave"
                  :param="insItemgrid.data"
                  mappingType="POST"
                  label="저장"
                  icon="save"
                  @beforeAction="saveClass"
                  @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-class-item',
  data() {
    return {
      gridClass: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'hazardMachineFlagNm',
            field: 'hazardMachineFlagNm',
            label: '유해위험기계기구 여부',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          // {
          //   name: 'useFlagNm',
          //   field: 'useFlagNm',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '362px'
      },
      insItemgrid: {
        columns: [
          {
            name: 'item',
            field: 'item',
            label: '항목',
            type: 'text',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            label: '항목설명',
            type: 'text',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemValue',
            field: 'itemValue',
            label: '값',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            label: '단위',
            type: 'text',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        hazardMachineFlag: null,
        useFlag: 'Y',
      },
      rowTypeName: '',
      editable: true,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      checkUrl: '',
      isSave: false,
      itemDetailUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      hazardMachineItems: [
        { code: 'Y', codeName: '유해위험기계기구' },
        { code: 'N', codeName: '일반' },
      ],
      key: {
        equipmentTypeCd: '',
        plantCd: '',
      },
      number: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.insertUrl = transactionConfig.mdm.equipment.class.item.insert.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.list.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.delete.url
      this.getEquipClassList();
    },
    getEquipClassList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
      },);
    },
    rowClick(row) {
      this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
      this.key.plantCd = this.$_.clone(row.plantCd);
      this.rowTypeName = this.$_.clone(row.equipmentTypeName);
      // 설비유형별 점검항목 조회
      this.updateMode = true;
      this.$http.url = this.$format(this.itemDetailUrl, row.equipmentTypeCd, row.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.insItemgrid.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      },
      () => {
      });
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비유형을 선택하세요.', // 설비유형을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      // 초기에는 userId에 tempId를 부여
      this.insItemgrid.data.splice(0, 0, {
        plantCd: this.key.plantCd,
        equipmentTypeCd: this.key.equipmentTypeCd,
        sortOrder: uuidv4(),
        item: '',
        itemDesc: '',
        itemValue: '',
        itemUnit: '',
        editFlag: 'C'
      });
    },
    saveClass() {
      if (this.insItemgrid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '세부항목을 하나 이상 입력하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
        // 중복체크
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd })
    },
    remove() {
      let selectData = this.$refs['classInsTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
                this.insItemgrid.data = this.$_.reject(this.insItemgrid.data, { sortOrder: item.sortOrder })
              })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
